import { useModal } from "../../../../utils/ModalContext";
import { FaCalendarAlt } from "react-icons/fa";
import BlogDetailsStyleWrapper from "./BlogDestails.style";
// components

import Sidebar from "../sidebar/Sidebar";

//images
import postThumb from "../../../../assets/images/blog/blog-img6.png";

import avatarIcon from "../../../../assets/images/blog/user.png";



const BlogDetails = () => {
  const { shareModalHandle } = useModal();
  return (
    <BlogDetailsStyleWrapper>
      <div className="blog_post_details_wrapper">
        <div className="container">
          <div className="row">
            {/* post details col  */}
            <div className="col-lg-8 col-md-12">
              <div className="post_thumbnail">
                <img src={postThumb} alt="bithu nft blog" />
              </div>
         
              <h1 className="post_title">
              Senoufo Kpeliye
              </h1>
              <div className="blog_description">
                <p className="text-white">
                L'art traditionnel des Senoufo en Côte d'Ivoire et au Mali est intimement lié à leur activité principale de subsistance : l'agriculture.
 Les rites agraires ont donné naissance, comme pour les Bambara, à une multitudes d'objets dont des masques liés à l'initiation Poro.
 On leur connaît aussi divers types de statues allant des grandes figures destinées à battre le rythme des danses, aux maternités et figures équestres. Une constante stylistique permet néanmoins généralement de les rallier entre elle : le visage incurvé et le menton prognathe projetant une bouche petites et dentée.
Ici je vous propose un kepliye en très bel état sortant pour les danses liées à l'initiation poro.

                </p>
                
                <div>
                  
               
                </div>
                
             
                
              </div>

              

             

              

              

              
            </div>

            {/* **********sidebar*********** */}
            <div className="col-lg-4 col-md-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  );
};

export default BlogDetails;
