import { Routes, Route } from "react-router-dom";
import HomeV1 from "../pages/homeV1";
import HomeV2 from "../pages/homeV2";
import HomeV3 from "../pages/homeV3";
import Blogs from "../pages/blogs";
import BlogDetails9 from "../pages/blogDetails9";
import BlogDetails8 from "../pages/blogDetails8";
import BlogDetails7 from "../pages/blogDetails7";
import BlogDetails6 from "../pages/blogDetails6";
import BlogDetails5 from "../pages/blogDetails5";
import BlogDetails4 from "../pages/blogDetails4";
import BlogDetails3 from "../pages/blogDetails3";
import BlogDetails2 from "../pages/blogDetails2";
import BlogDetails from "../pages/blogDetails";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeV1 />} exact />
      <Route path="/home-two" element={<HomeV2 />} />
      <Route path="/home-three" element={<HomeV3 />} />
      <Route path="/atcs" element={<Blogs />} />
      <Route path="/Bambara" element={<BlogDetails/>} />
      <Route path="/PENDE" element={<BlogDetails2/>} />
      <Route path="/DAN" element={<BlogDetails3/>} />
      <Route path="/pwo" element={<BlogDetails4/>} />
      <Route path="/Tshokwe" element={<BlogDetails5/>} />
      <Route path="/Senoufo" element={<BlogDetails6/>} />
      <Route path="/CHOKWE" element={<BlogDetails7/>} />
      <Route path="/DAN2" element={<BlogDetails8/>} />
      <Route path="/Marka" element={<BlogDetails9/>} />
    </Routes>
  );
}

export default App;
