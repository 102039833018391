import { useState, useEffect } from "react";
import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

import characterThumb from "../../../../assets/images/nft/carousel_avater4.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";

import ATC from '../../../../artifacts/contracts/ATC.sol/ATC.json';
import { ethers } from 'ethers';

import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

const GGaddress = "0xcd329576dff3a315dcace0e40be2cbc1e2d09b4e"; 

const Banner = () => {

  const [data, setData] = useState({});
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(GGaddress, ATC.abi, provider);
      try {
        const cost = await contract.publicSalePrice();
        const sellingStep = await contract.sellingStep();
        console.log(parseInt(cost) / 10 ** 18);
        const totalSupply = await contract.totalSupply();
        const object = { "cost": String(cost), "totalSupply": String(totalSupply), "sellingStep": String(sellingStep) }
        setData(object);
      }
      catch (err) {
        /*setError(err.message);*/
      }
    }
  }

  const { mintModalHandle } = useModal();
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <h2>African Tribes Club</h2>
              <h3>
                <span className="count">
                  <span>{data.totalSupply}</span>

                </span>{" "}
                / 10000 Minted
              </h3>
              <div className="banner_buttons">
                <Button lg variant="mint" onClick={() => mintModalHandle()}>
                  {" "}
                  Mint now
                </Button>
{/*
                <CrossmintPayButton
                  collectionTitle="<TITLE_FOR_YOUR_COLLECTION>"
                  collectionDescription="<DESCRIPTION_OF_YOUR_COLLECTION>"
                  collectionPhoto="<OPT_URL_TO_PHOTO_COVER>"
                  clientId="<YOUR_CLIENT_ID>"
                  environment="<YOUR_DESIRED_ENVIRONMENT>"
                  mintConfig={{
                    count: "<NUMBER_OF_NFTS>",
                    price: "<SELECTED_PRICE>"
                    // your custom minting arguments...
                  }}
                /> */} 

              </div>
              <div className="coin-info">
                <span>Max 10 NFTs per wallet . Price 0.02 ETH + gas</span>
                <span>
                  MINT IS LIVE{" "}

                </span>

              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="mint_live_circle_sect">
                  <div className="mint_live_circle">
                    <span>
                      <img src={mintLiveDownArrow} alt="" />
                    </span>
                    <span className="mint_live_text rotated-style">
                      <img src={mintLiveText} alt="" />
                    </span>
                  </div>
                </div>
                <div className="bithu_v1_baner_right_img_bg">
                  <img src={homeImageBG} alt="" />
                </div>
                <div className="bithu_v1_baner_right_img">
                  <img src={characterThumb} alt="avater" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
