import thumb1 from "../../images/blog/blog-img.png";
import thumb2 from "../../images/blog/blog-img2.png";
import thumb3 from "../../images/blog/blog-img3.png";
import thumb4 from "../../images/blog/blog-img4.png";
import thumb5 from "../../images/blog/blog-img5.png";
import thumb6 from "../../images/blog/blog-img6.png";
import thumb7 from "../../images/blog/blog-img7.png";
import thumb8 from "../../images/blog/blog-img8.png";
import thumb9 from "../../images/blog/blog-img9.png";

const data = {
  posts: [
    {
      thumbnail: thumb1,
      title: "Utilisées lors des cérémonies d'initiation du mukanda ...",
      excerpt:
       "",
      
      categories: [
        {
          title: "PENDE MBUYA",
          url: "/PENDE",
          
        },
      ],
    },
    {
      thumbnail: thumb2,
      author: "Bambara",
      title: "Bambara : bamanan; pluriel,Bamananw, Bamana ou Banmana ...",
      
      
      categories: [
        {
          title: "BAMBARA",
          url:"/Bambara",
          
        },
      ],
    },
    {
      thumbnail: thumb3,
     
      title: "En Côte d'Ivoire, de dangereux esprits de la forêt ...",
      categories: [
        {
          title: "DAN GUERZÉ GAGON",
          url:"/DAN",
         
        },
       
      ],
    },
    {
      thumbnail: thumb4,
      
      title: "Les masques Mwana pwo (jeune femme), sont dansés ...",
      
      categories: [
        {
          title: "Tshokwe Mwana Pwo",
          url:"/Tshokwe",
         
        },
      ],
    },
    {
      thumbnail: thumb5,
     
      title: "Ce masque africain Tshokwe figurant une jeune femme ...",
      
      categories: [
        {
          title: "pwo",
          url: "/pwo",
        },
      ],
    },
    {
      thumbnail: thumb6,
      
      title: "L'art traditionnel des Senoufo en Côte d'Ivoire et au Mali ... ",
      
      categories: [
        {
          title: "Senoufo Kpeliye",
          url:"/Senoufo",
        
        },
      ],
    },
    {
      thumbnail: thumb7,
     
      title: "Les Chokwe etaient installés en Angola, au Congo ...",
      
      categories: [
        {
          title: "CHOKWE PWO",
          url: "/CHOKWE",
        },
      ],
    },
    {
      thumbnail: thumb8,
      
      title: "Il servait de marqueur social",
      
      categories: [
        {
          title: "DAN",
          url: "/DAN2",
        },
        
      ],
    },
    {
      thumbnail: thumb9,
      title: "Chez les Marka, Do est l'esprit auquel les masques ...",
      
      categories: [
        {
          title: "Marka",
          url: "/Marka",
        },
      ],
    },
  ],
};

export default data;
