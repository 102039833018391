import { FaRegComments } from "react-icons/fa";

import BlogItemStyleWrapper from "./BlogItem.style";

const BlogItem = ({ thumbnail, title, timeStamp, categories, excerpt }) => {
  return (
    <BlogItemStyleWrapper className="single_blog_item">
      <div className="blog_thumb">
        <a href="/post">
        {categories?.map((category, i) => (
            <a key={i} href={category.url} className="blog_title">
              {i > 0 ? ", " : ""}
              <img src={thumbnail} alt="bithu nft blog" />
            </a>
          ))}
          
          
        </a>
      </div>
      <div className="blog_content">
        <div className="blog_meta">
          {categories?.map((category, i) => (
            <a key={i} href={category.url} className="category_title">
              {i > 0 ? ", " : ""}
              {category.title}
            </a>
          ))}
         
          
        </div>

        <a className="blog_title" href="/post">
        {categories?.map((category, i) => (
            <a key={i} href={category.url} className="blog_title">
              {i > 0 ? ", " : ""}
              {title}
            </a>
          ))}
          
        </a>
        <p>{excerpt} </p>
        <div className="blog_footer">
          <div className="read_more_btn">
          {categories?.map((category, i) => (
            <a key={i} href={category.url} className="category_title">
              {i > 0 ? ", " : ""}
              <a> Read more</a>
            </a>
          ))}
            
          </div>
        
        </div>
      </div>
    </BlogItemStyleWrapper>
  );
};

export default BlogItem;