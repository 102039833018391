import { useModal } from "../../../utils/ModalContext";
import { useState, useEffect } from "react";

import { FiX, FiChevronRight } from "react-icons/fi";
import WalletModalStyleWrapper from "./WalletModal.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import metamaskIcon from "../../../assets/images/icon/MetaMask.svg";
import formatic from "../../../assets/images/icon/Formatic.svg";
import trustWalletIcon from "../../../assets/images/icon/Trust_Wallet.svg";
import walletConnect from "../../../assets/images/icon/WalletConnect.svg";

import { ethers } from 'ethers';

const WalletModal = () => {
  const { walletModalHandle } = useModal();
  const [account, setAccount] = useState([]);

    // Requests access to the user's META MASK WALLET
  // https://metamask.io
  async function requestAccountMetaMask() {
    console.log('Requesting account...');

    if (window.ethereum.isMetaMask) {
      console.log('detected MetaMask');

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
              console.log('detected MetaMask in Try');
              alert('Perfect ! You are connected successfully');


      } catch (error) {
        console.log('Error connecting...');
        alert(error);
      }

    } else {
      alert('Meta Mask not detected');
    }
  }

  async function requestAccountCoinBase() {
    console.log('Requesting account...');

    if (window.ethereum.isCoinbaseWallet) {
      console.log('detected CoinBase');

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
              console.log('detected CoinBase in Try');
              alert('Perfect ! You are connected successfully');


      } catch (error) {
        console.log('Error connecting...');
        alert(error);
      }

    } else {
      alert('CoinBase not detected');
    }
  }

  return (
    <>
      <WalletModalStyleWrapper className="modal_overlay">
        <div
          className="mint_modal_box"
        >
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>CONNECT WALLET</h2>
              <button onClick={() => walletModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <p>
                Please select a wallet to connect for start Minting your NFTs
              </p>

              <div className="wallet_list">

                <a href="# " onClick={requestAccountMetaMask}>
                  <img src={metamaskIcon} alt="Meta-mask" />
                  MetaMask
                  <span>
                    <FiChevronRight />
                  </span>
                </a>

                <a href="# " onClick={requestAccountCoinBase}>
                  <img src={formatic} alt="Coinbase" />
                  Coinbase
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
                <a href="# ">
                  <img src={trustWalletIcon} alt="Trust" />
                  Trust Wallet
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
                <a href="# ">
                  <img src={walletConnect} alt="Wallet" />
                  WalletConnect
                  <span>
                    <FiChevronRight />
                  </span>
                </a>
              </div>
              <div className="modal_bottom_text">
                By connecting your wallet, you agree to our
                <a href="# ">Terms of Service</a>
                <a href="# ">Privacy Policy</a>
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletModal;
