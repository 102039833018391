import { useModal } from "../../../../utils/ModalContext";
import { FiShare2 } from "react-icons/fi";
import { FaCalendarAlt } from "react-icons/fa";
import BlogDetailsStyleWrapper from "./BlogDestails.style";
// components

import Sidebar from "../sidebar/Sidebar";

//images
import postThumb from "../../../../assets/images/blog/blog-img2.png";

import avatarIcon from "../../../../assets/images/blog/user.png";



const BlogDetails = () => {
  const { shareModalHandle } = useModal();
  return (
    <BlogDetailsStyleWrapper>
      <div className="blog_post_details_wrapper">
        <div className="container">
          <div className="row">
            {/* post details col  */}
            <div className="col-lg-8 col-md-12">
              <div className="post_thumbnail">
                <img src={postThumb} alt="bithu nft blog" />
              </div>
              <div className="blog_post_meta">
               
               
              </div>
              <h1 className="post_title">
              Bambara
              </h1>
              <div className="blog_description">
                <p className="text-white">
                Les Bambaras (bambara : bamanan; pluriel,Bamananw, Bamana ou Banmana) sont un peuple mandingue de l'Afrique de l'Ouest sahélienne, établi principalement au Mali. Ils formaient le "Royaume bambara de Ségou" d’Afrique de l'Ouest. Ils parlent le bambara.
                </p>
                <p>
                Les Banmana ou Bambaras sont originaires du Mandé. Ils auraient quitté cette région pour échapper à la domination des Malinkés, à l'époque de l'Empire du Mali. D'où leur nom qui signifie « ceux qui ont refusé de se soumettre » (de ban = « refus » et mana = « maître »). D'autre traduisent cette étymologie par "ceux qui ont refusé de se soumettre (à l'islam)". Ils iront vivre au Ouassoulou, entre Sikasso et la Côte d’Ivoire, au Do et Kri (actuel Ségou), au Kaniaga (actuel; Bélédougou), au Kaarta (Sandaré, Oussoubidjadjan, etc.). En réalité, les Bambaras ne sont qu'un sous-groupe des Malinkés, tout comme beaucoup d'autres groupes Mandingues.
                </p>
                <div>
                 
                 
                  <p>
                  Au XIXe siècle, les royaumes bambaras du Kaarta et de Ségou, créés par les deux frères Niangolo et Baramanglo, de patronyme Coulibaly, après la chute de l'empire sonhrai, résistent à l'empire peul du Macina, puis temporairement au Toucouleur el Hadj Oumar Tall, qui souhaite convertir les animistes à l'islam. La résistance acharnée des Bambaras contre el Hadj Oumar fut telle que celui-ci n'a jamais pu créer un État stable. Ils sont aujourd'hui majoritairement musulmans, il y a quelques petits groupes chrétiens. Les autres continuent de pratiquer la religion traditionnelle. De nombreux rites et traditions du passé sont présents aujourd'hui dans leur culture, qu'ils soient musulmans ou autres.
Les masques sont connus sous le nom de l'Art Bambara ou Bamana avant d’être islamisé de force via le Soudan. Comme pour beaucoup de peuples africains, la spiritualité régit toute la vie du Bambara. Les traditions et coutumes, les décisions politiques, sont toutes liées à la spiritualité. Il n'y a pas de séparation entre la religion et la tradition. Les Bambaras ont influencé beaucoup d'ethnies culturellement, les Khassonkés, Dyula et Malinkés. Les Sénoufos ont beaucoup de points communs avec les Bambaras. Les Peuls du Ouassoulou ont complètement été absorbés par les Bambaras.
L'homme bambara s'habille en boubou, où des dessins sont brodés, qu'il porte par dessus une courte tunique. Le pantalon est bouffant. 
Les hommes ont parfois un anneau d'or à l'oreille.

                  </p>
                </div>
               
             
                
              </div>

              

             

              

              

              
            </div>

            {/* **********sidebar*********** */}
            <div className="col-lg-4 col-md-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  );
};

export default BlogDetails;
