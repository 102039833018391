import { useModal } from "../../../../utils/ModalContext";
import { FaCalendarAlt } from "react-icons/fa";
import BlogDetailsStyleWrapper from "./BlogDestails.style";
// components

import Sidebar from "../sidebar/Sidebar";

//images
import postThumb from "../../../../assets/images/blog/blog-img7.png";

import avatarIcon from "../../../../assets/images/blog/user.png";



const BlogDetails = () => {
  const { shareModalHandle } = useModal();
  return (
    <BlogDetailsStyleWrapper>
      <div className="blog_post_details_wrapper">
        <div className="container">
          <div className="row">
            {/* post details col  */}
            <div className="col-lg-8 col-md-12">
              <div className="post_thumbnail">
                <img src={postThumb} alt="bithu nft blog" />
              </div>
         
              <h1 className="post_title">
              CHOKWE PWO
              </h1>
              <div className="blog_description">
                <p className="text-white">
                Les Chokwe etaient installés en Angola, au Congo et en Zambie.
Des groupes proches y sont apparentés tels les Holo, Luena (Lwena), Luvale (Lovale).
Ce NFT est un pwo ou mwana pwo figure une jeune femme dans la fleur de l'âge. Il était porté par un danseur enseignant aux jeunes filles une belle démarche. 
Le visage a été couvert de pigment rouge appelé tukula. On notera aussi que les scarifications bien présentes.

                </p>
                
                <div>
                  
               
                </div>
                
             
                
              </div>

              

             

              

              

              
            </div>

            {/* **********sidebar*********** */}
            <div className="col-lg-4 col-md-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  );
};

export default BlogDetails;
