import { useModal } from "../../../../utils/ModalContext";
import { FaCalendarAlt } from "react-icons/fa";
import BlogDetailsStyleWrapper from "./BlogDestails.style";
// components

import Sidebar from "../sidebar/Sidebar";

//images
import postThumb from "../../../../assets/images/blog/blog-img3.png";

import avatarIcon from "../../../../assets/images/blog/user.png";



const BlogDetails = () => {
  const { shareModalHandle } = useModal();
  return (
    <BlogDetailsStyleWrapper>
      <div className="blog_post_details_wrapper">
        <div className="container">
          <div className="row">
            {/* post details col  */}
            <div className="col-lg-8 col-md-12">
              <div className="post_thumbnail">
                <img src={postThumb} alt="bithu nft blog" />
              </div>
         
              <h1 className="post_title">
              DAN GUERZÉ GAGON
              </h1>
              <div className="blog_description">
                <p className="text-white">
                Dans la culture Dan, en Côte d'Ivoire, de dangereux esprits de la forêt sont traduits, dans leur art traditionnel, sous forme de masques à visage humain.
Ces masques sont spirituellement chargés puisqu’ils ne représentent pas simplement des esprits comme au sein d’autres groupes ethniques mais sont habités par l’esprit même.
Les danseurs, gle-zo, ont des visions envoyées par les esprits des masques leur permettant d’effectuer leur danse.
Ils s’articulent selon un système hiérarchique qui domine la vie politique et religieuse. Les esprits et donc les masques peuvent donc être classés par types.

Aujourd’hui, ils incarnent habituellement le beau ou tout simplement l’objet curieux qui attire ou qui repousse.
En Afrique de l’ouest, chaque masque joue un rôle spécifique en fonction de la société ou du peuple auquel il appartient. 
Ils sont utilisés lors des rites tribaux et des cérémonies d’envergures pour entretenir, et parfois rétablir l’ordre entre le monde des humains et celui des esprits. C’est le cas des masques utilisés lors de la cérémonie Gèlèdè chez le peuple Yorouba, originaire du Nigéria, également présent au Bénin et au Togo.

                </p>
                
                <div>
                  
               
                </div>
                
             
                
              </div>

              

             

              

              

              
            </div>

            {/* **********sidebar*********** */}
            <div className="col-lg-4 col-md-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  );
};

export default BlogDetails;
