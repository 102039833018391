import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    thumb: openseaIcon,
    url: "https://opensea.io/collection/african-tribes-club",
  },
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/miriblonNTF",
  },
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/miriblon/",
  },

  {
    thumb: mediumIcon,
    url: "#",
  },
];

export default data;
