import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: checkIcon,
        title: "Phase 00",
        subtitle: "0%",
        text: "Create discord, instagram, twitter account and website"
    }, 
    {
        icon: checkIcon,
        title: "Phase 01",
        subtitle: "10%",
        text: "Twitter Competition activated and One lucky winner will win an NFT"
    }, 
    {
        icon: checkIcon,
        title: "Phase 02 Displate Competition activated",
        subtitle: "20%",
        text: "10 lucky winners will receive a branded Displate from us. The Twitter competition jumps in size by 5, there will now be 5 winners of various NFTs."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 03 Carbon Negative",
        subtitle: "30%",
        text: "The project as a whole will go carbon negative, meaning in a way, it could be claimed, that this project helped the environment. The Twitter competition jumps in size by 10 from the original, there will now be 10 of a variety of NFTs."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 04 Merch activated",
        subtitle: "40%", 
        text: "We will begin work on merch for our community!"
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 05 Huge Community Wallet",
        subtitle: "50%", 
        text: "If we hit this milestone, 25% of the rest of the sale goes into this Community Wallet."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 06 Profile Picture Advertisement",
        subtitle: "60% ", 
        text: "You will be paid to have your discord and Twitter profile as your profile picture, no more advertising for free, these funds will come from the sale not the Community Wallet at first."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 07 Charity Donation activated",
        subtitle: "70%", 
        text: "5% of the sale will go to a Charity. The Community will vote on the final Charity that will get these funds."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 08 Community Buy Back",
        subtitle: "80%", 
        text: "5% of the sale funds will be pledged to buy the floor. These will then be used for the next Roadmap item."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 09 Liquidity Pool",
        subtitle: "90%", 
        text: "The masks we have bought in the 80% target on the roadmap will then be used along with a substantial amount of ETH to fund an NFT liquidity pool."
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 10 Online African tribal ceremony",
        subtitle: "100%", 
        text: "Our resident Artist is well versed in animation and if we completely sell out will produce an animated video including masks from the community. The community will vote on the masks and which of the proposed story lines they like. This will take time, but we are looking forward to it!"
    }, 
]

export default data;