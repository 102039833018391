import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'African tribes Club believe ATC NFT can provide a vital gateway into the spiritual world when worn during rituals and ceremonies, so they hold a special sacred significance.African Tribes Club NFT will become mediators between the living world and the supernatural world of the dead, ancestors and other entities.ATC NFT is made according to a traditional style, and can be for a certain online traditional ceremony.',
    aboutDescription2: 'African Tribes Club NFTs are still looked at as an important part of African culture and history and are will be used in important online rituals and celebrations.Elaborate and complex beliefs surround ATC NFTs and their use in online rituals.And the wood used for certain African masks is so powerful that it may only be touched by a Virtually everything associated with ATC NFT making has a corresponding ritual. During the virtual African Tribes Club ceremony the ATC member goes into deep trance, and during this state of mind he "communicate" with his ancestors. A wise man or translator sometimes accompanies the ATC member during the ritual. The ATC member brings forth messages of wisdom from his ancestors. Often the messages are grunted utterances and the translator will accurately decipher the meaning of the message. Rituals and ceremonies are always accompanied with song, dance and music, played with traditional African musical instruments.It is believed that the earliest masks were used in Africa before Paleolithic era. They represent spirits of animals or ancestors, mythological heroes, moral values or a form of honoring of a person in a symbolic way.',
    aboutInfo: [
      
        
    ]
}

export default data;