import { useModal } from "../../../../utils/ModalContext";
import { FaCalendarAlt } from "react-icons/fa";
import BlogDetailsStyleWrapper from "./BlogDestails.style";
// components

import Sidebar from "../sidebar/Sidebar";

//images
import postThumb from "../../../../assets/images/blog/blog-img4.png";

import avatarIcon from "../../../../assets/images/blog/user.png";



const BlogDetails = () => {
  const { shareModalHandle } = useModal();
  return (
    <BlogDetailsStyleWrapper>
      <div className="blog_post_details_wrapper">
        <div className="container">
          <div className="row">
            {/* post details col  */}
            <div className="col-lg-8 col-md-12">
              <div className="post_thumbnail">
                <img src={postThumb} alt="bithu nft blog" />
              </div>
         
              <h1 className="post_title">
              Tshokwe Mwana Pwo
              </h1>
              <div className="blog_description">
                <p className="text-white">
                Les masques Mwana pwo (jeune femme), sont dansés par des hommes Chokwe dans des festivals principalement pour le divertissement, conféreraient une fertilité accrue aux spectateurs. 
Les masques représentent des ancêtres féminines représentées comme de belles jeunes femmes, avec un front haut, des traits équilibrés, des dents limées et une scarification. Les marques de scarification, qui peuvent reproduire celles de la femme réelle dont la beauté a inspiré le dessinateur, incluent le dessin cingelyengelye sur le front. Cette conception cruciforme était probablement dérivée de pendentifs en étain commercialisés dans la région de Chokwe par des voyageurs portugais dès le XVIIe siècle.

                </p>
                
                <div>
                  
               
                </div>
                
             
                
              </div>

              

             

              

              

              
            </div>

            {/* **********sidebar*********** */}
            <div className="col-lg-4 col-md-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  );
};

export default BlogDetails;
