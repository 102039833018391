import { useModal } from "../../../../utils/ModalContext";
import { FaCalendarAlt } from "react-icons/fa";
import BlogDetailsStyleWrapper from "./BlogDestails.style";
// components

import Sidebar from "../sidebar/Sidebar";

//images
import postThumb from "../../../../assets/images/blog/blog-img9.png";

import avatarIcon from "../../../../assets/images/blog/user.png";



const BlogDetails = () => {
  const { shareModalHandle } = useModal();
  return (
    <BlogDetailsStyleWrapper>
      <div className="blog_post_details_wrapper">
        <div className="container">
          <div className="row">
            {/* post details col  */}
            <div className="col-lg-8 col-md-12">
              <div className="post_thumbnail">
                <img src={postThumb} alt="bithu nft blog" />
              </div>
         
              <h1 className="post_title">
              Marka
              </h1>
              <div className="blog_description">
                <p className="text-white">
                Chez les Marka, Do est l'esprit auquel les masques donnent forme et constitue son expression.
Do vient de la brousse par le masque de feuilles et dans certains cas mythiques par les masques de fibres et à tête en bois sculpté.
Dans le système religieux de la société marka, do est le plus invoqué et son culte le plus élaboré.
Les Marka sont organisés en sociétés de masques structurées et hiérarchisées.
Il existe un langage initiatique moyen de communication aux mains des initiés.
Les masques Marka sont une synthèse de composantes stylistiques des peuples mandé apparentés du Mali, et de leurs voisins du Burkina Faso.
Des Mandé, ils ont gardé le visage ovale aux joues plates, et le "T" formé par l'intersection du nez et des arcades sourcillières.
Les yeux, petits et carrés, sont placés dans l'angle du "T".
Les oreilles, grandes, sont disposées horizontalement. La bouche protubérante avance au dessus d'une barbe large et triangulaire. Le visage est surmonté d'une crête incurvée vers l'arrière comportant souvent de courtes planchettes dentelées, des croissants ou des cornes qu'encadre une forme animale.
A leurs voisins, les Marka ont emprunté les motifs géométriques comprenant des triangles, des chevrons, des damiers et des cercles concentriques pour matérialiser les yeux.
Les Marka possèdent d'autres masques zoomorphes semblables à ceux de leurs voisins Nuna et Bwaba.

                </p>
                
                <div>
                  
               
                </div>
                
             
                
              </div>

              

             

              

              

              
            </div>

            {/* **********sidebar*********** */}
            <div className="col-lg-4 col-md-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  );
};

export default BlogDetails;
