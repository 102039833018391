import { useModal } from "../../../../utils/ModalContext";
import { FaCalendarAlt } from "react-icons/fa";
import BlogDetailsStyleWrapper from "./BlogDestails.style";
// components

import Sidebar from "../sidebar/Sidebar";

//images
import postThumb from "../../../../assets/images/blog/blog-img.png";

import avatarIcon from "../../../../assets/images/blog/user.png";



const BlogDetails = () => {
  const { shareModalHandle } = useModal();
  return (
    <BlogDetailsStyleWrapper>
      <div className="blog_post_details_wrapper">
        <div className="container">
          <div className="row">
            {/* post details col  */}
            <div className="col-lg-8 col-md-12">
              <div className="post_thumbnail">
                <img src={postThumb} alt="bithu nft blog" />
              </div>
         
              <h1 className="post_title">
              PENDE MBUYA
              </h1>
              <div className="blog_description">
                <p className="text-white">
                Utilisées lors des cérémonies d'initiation du mukanda pour marquer la fin des rituels de la circoncision masculine, les mascarades de mbuya sont maintenant utilisées pour mettre en valeur un certain nombre de personnages et de rôles dans la société Pende.
                </p>
                
                <div>
                  
               
                </div>
                
             
                
              </div>

              

             

              

              

              
            </div>

            {/* **********sidebar*********** */}
            <div className="col-lg-4 col-md-12">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </BlogDetailsStyleWrapper>
  );
};

export default BlogDetails;
