import { useState, useEffect } from "react";
import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import Button from "../../button";
import MintModalStyleWrapper from "./MintNow.style";
import mintImg from "../../../assets/images/nft/alo.png";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";

import ATC from '../../../artifacts/contracts/ATC.sol/ATC.json';

import { ethers } from 'ethers';
const GGaddress = "0xcd329576dff3a315dcace0e40be2cbc1e2d09b4e";
let quantity;


const MintNowModal = () => {
  const [count, setCount] = useState(1);
  quantity = parseInt(count);

  const { mintModalHandle } = useModal();

  const [error, setError] = useState('');
  const [data, setData] = useState({});
  const [account, setAccount] = useState([]);
  const [success, setSuccess] = useState('');
  useEffect(() => {
    fetchData();
    getAccounts();
  }, []);

  async function getAccounts() {
    if (typeof window.ethereum !== 'undefined') {

      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      setAccount(accounts);
      console.log(accounts[0]);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const balance = await provider.getBalance(accounts[0]);
      /*  const balanceInEth = ethers.utils.formatBytes32String*/
      const balanceInEth = ethers.utils.formatEther(balance);
      console.log(balanceInEth);

    }
  }



  async function fetchData() {
    if (typeof window.ethereum !== 'undefined') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(GGaddress, ATC.abi, provider);
      try {
        const cost = await contract.publicSalePrice();
        const sellingStep = await contract.sellingStep();
        console.log(parseInt(cost) / 10 ** 18);
        const totalSupply = await contract.totalSupply();
        const object = { "cost": String(cost), "totalSupply": String(totalSupply), "sellingStep": String(sellingStep) }
        setData(object);
      }
      catch (err) {
        /*setError(err.message);*/
      }
    }
  }

  async function publicSaleMint() {
    if (typeof window.ethereum !== 'undefined') {
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(GGaddress, ATC.abi, signer);

      try {
        let overrides = {
          from: accounts[0],
          value: ''+data.cost  * quantity+''
        }
        const transaction = await contract.publicSaleMint(accounts[0], quantity, overrides);
        await transaction.wait();
        fetchData();
      }
      catch (err) {
        setError(err.message);
        console.log( data.cost / 10 **18 );
      }
    } 
  }

  return (
    <>
      <MintModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Collect YOUR NFT before end</h2>
              <button onClick={() => mintModalHandle()}>
                <FiX />
              </button>
            </div>
            <div className="modal_body text-center">
              <div className="mint_img">
                <img src={mintImg} alt="bithu nft mint" />
              </div>
              <div className="mint_count_list">
                <ul>
                  <li>
                    <h5>Remaining</h5>
                    <h5>
                      {data.totalSupply}/<span>10000</span>
                    </h5>
                  </li>
                  <li>
                    <h5>Price</h5> 
                    <h5>{data.cost / 10**18 } ETH</h5>
                  </li>
                  <li>
                    <h5>Quantity</h5>
                    <div className="mint_quantity_sect">
                      <button
                        onClick={() =>
                          count > 1 ? setCount(count - 1) : count
                        }
                      >
                        -
                      </button>
                      <input
                        type="text"
                        id="quantity"
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                      />
                      <button onClick={() => setCount(count + 1)}>+</button>
                    </div>
                    <h5>
                      <span>{data.cost / 10 ** 18 * count}</span> ETH
                    </h5>
                  </li>
                </ul>
              </div>
              <div className="modal_mint_btn">
                <Button lg variant="mint" onClick={publicSaleMint}> 
                  Mint Now
                  </Button>
             
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </MintModalStyleWrapper>
    </>
  );
};

export default MintNowModal;
