const data = [
  {
    title: "Virtual African Tribes Club ceremony By ATC",
    text: "African tribes Club believe ATC NFT can provide a vital gateway into the spiritual world when worn during rituals and ceremonies, so they hold a special sacred significance.African Tribes Club NFT will become mediators between the living world and the supernatural world of the dead, ancestors and other entities.ATC NFT is made according to a traditional style, and can be for a certain online traditional ceremony.African Tribes Club NFTs are still looked at as an important part of African culture and history and are will be used in important online rituals and celebrations.Elaborate and complex beliefs surround ATC NFTs and their use in online rituals.And the wood used for certain African masks is so powerful that it may only be touched by a Virtually everything associated with ATC NFT making has a corresponding ritual. During the virtual African Tribes Club ceremony the ATC member goes into deep trance, and during this state of mind he communicate with his ancestors. A wise man or translator sometimes accompanies the ATC member during the ritual. The ATC member brings forth messages of wisdom from his ancestors. Often the messages are grunted utterances and the translator will accurately decipher the meaning of the message. Rituals and ceremonies are always accompanied with song, dance and music, played with traditional African musical instruments.It is believed that the earliest masks were used in Africa before Paleolithic era. They represent spirits of animals or ancestors, mythological heroes, moral values or a form of honoring of a person in a symbolic way.",
  },
  {
    title: "ATC NFTs Are Deeply Connected to the Spirit World",
    text: "African Tribes Club member believe that using the ATC NFT during ritualized performances such as  Virtual African Tribes Club ceremony By ATC (weddings, funerals and secret society initiations) can connect them with spirits beyond the real world.During such performances, the ATC member enters a trance-like state that tribes believe will allow them to communicate with ancestors, or to control the forces of good and evil.",
  },
  {
    title: "African Tribes Club NFTs by African tribal artists",
    text: "African Tribes Club (ATC) NTF is a living tradition that continues today. Amazingly, this tradition dates back many millennia, and the specific skills needed to create these objects have been passed on through many different generations. African tribal artists  are trained for several years, either as an apprentice to a master carver.Sometimes a father shares his skills with his son, continuing their craft through the family line. These artists hold a respectable role in African tribal society, as the creator of such spiritually significant objects.",
  },
  {
    title: "ATC NFTs Are Designed to be Worn by a Select Few ",
    text: "Our Collections are reserved for specific members. Only a select few tribe leaders are endowed with the honor of being a ATC NFT wearer. They are almost always men, and often elders within the tribe, who have earned wisdom and respect over the years. When they wear the ATC, tribes believe they become the spirit they wish to invoke",
  },
  {
    title: "ATC NFT Take Different Forms",
    text: "Not all ATC NFTs cover the head in the same way. Some are designed to cover the face only, tied with a band or strong, while others have a helmet-like appearance that covers the whole head. Some of these helmet-like NFTs are carved out of an entire tree trunk! Other NFT can cover the whole head and shoulder area, with a heavy base that sits upon the wearer’s shoulders, giving them a commanding and even terrifying air of authority.",
  },
  
];

export default data;
